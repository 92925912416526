<style lang="scss">

</style>

<template>
    <div class="overflow-hidden">
        <v-row v-for="(item, index) in parametro.rangos" :key="index">
        <!-- <v-row v-for="(item, index) in rangoParametro" :key="index"> -->
          <v-col cols="12" class="py-0">
            <table class="c-table-rangos" width="100%">
              <tr v-if="item.borrado != 1" class="c-table-rangos__tr">
                <td class="c-table-rangos__td c-table-rangos__td-rango" width="200">
                  <div class="d-flex align-items-center ml-3">
                    <label class="c-table-rangos__label-rango ml-0"
                    :for="`rango_1_${index}`">{{ $t('ensayos.rango1') }}</label>
                    <v-text-field
                      v-model="item.nu_rango_a"
                      v-on:keyup="validarRangos(item, index)"
                      :class="index"
                      class="c-table-rangos__input-rango"
                      outlined
                      :id="`rango_1_${index}`"
                      dense
                      require
                    ></v-text-field>
                  </div>
                </td>
                <td
                class="c-table-rangos__td c-table-rangos__td-potencia c-table-rangos__td--120px"
                width="80">
                  <div class="d-flex align-items-center ml-3">
                    <label class="c-table-rangos__label-potencia"
                    :for="`potencia_a_${index}`">x10</label>
                    <v-text-field
                      v-model="item.nu_potencia_a"
                      v-on:keyup="validarRangos(item, index)"
                      class="c-table-rangos__input-potencia"
                      label=""
                      :id="`potencia_a_${index}`"
                      outlined
                      dense
                      require
                    ></v-text-field>
                  </div>
                </td>
                <td class="c-table-rangos__td c-table-rangos__td-rango">
                  <div class="d-flex align-items-center ml-3">
                    <label class="c-table-rangos__label-rango"
                    :for="`rango_b_${index}`">{{ $t('ensayos.rango2') }}</label>
                    <v-text-field
                      v-model="item.nu_rango_b"
                      v-on:keyup="validarRangos(item, index)"
                      class="c-table-rangos__input-rango"
                      label=""
                      :id="`rango_b_${index}`"
                      outlined
                      dense
                      require
                    ></v-text-field>
                  </div>
                </td>
                <td class="c-table-rangos__td c-table-rangos__td-potencia">
                  <div class="d-flex align-items-center ml-3">
                    <label class="c-table-rangos__label-potencia"
                    :for="`potencia_b_${index}`">x10</label>
                    <v-text-field
                      v-model="item.nu_potencia_b"
                      v-on:keyup="validarRangos(item, index)"
                      class="c-table-rangos__input-potencia"
                      label=""
                      :id="`potencia_b_${index}`"
                      outlined
                      dense
                      require
                    ></v-text-field>
                  </div>
                </td>
                <td class="c-table-rangos__td c-table-rangos__td-delete">
                  <span class="o-btn-range-value-remove mx-6" @click="removerRango(item, index)">
                    <img width="17" src="/img/eliminar.svg" class="">
                  </span>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
            <span class="c-btn o-btn-range-value-add" @click="agregarRango()">
              <!-- eslint-disable max-len -->
              <svg data-v-42f1b7de="" width="17.5" height="17.5" viewBox="0 0 17.5 17.5" class="mr-2"><g data-v-42f1b7de="" id="plus-circle" transform="translate(-0.869 -0.869)"><circle data-v-42f1b7de="" id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></circle><line data-v-42f1b7de="" id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line><line data-v-42f1b7de="" id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line></g></svg>
              <!-- eslint-enable max-len -->
              {{ $t('ensayos.agregar_rango') }}
            </span>
        </v-row>
    </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import i18n from '../../plugins/il8n';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      rangos_temporales: []
    }
  },
  computed: {
    ...mapState('Ensayos', ['form_data', 'data_ensayo', 'rangos']),
    rangoParametro() {
      console.log('filtro rangosss',this.rangos);
      const filtroRango = this.rangos.filter(rango => rango.cod_pat == parametro.cod_pat);
      console.log('filtro rango', filtroRango, parametro.cod_pat);
      return JSON.parse(JSON.stringify(filtroRango));
    }
  },
  props: {
    parametro: Object
  },
  created() {
  console.log('filtro rangoss', this.data_ensayo); 
  },
  methods: {
    validarRangos(item, index) {

      console.log('rangos temporales', this.rangos_temporales);
      const rangos = {...item};
      
      // TODO: Revisar condicion porque esta entrando al if aunque rango a y potencia a esten vacios
      if(item.nu_rango_a != null && item.nu_rango_b != null && item.nu_potencia_a != null && item.nu_potencia_b != null) {

        let rango_a = item.nu_rango_a * Math.pow(10, item.nu_potencia_a);
        let rango_b = item.nu_rango_b * Math.pow(10, item.nu_potencia_b);
        
        if(rango_a >= rango_b) {
          Swal.fire({
            title: 'Error',//i18n.t('solicitudes.Success'),
            text: i18n.t('ensayos.validacion_rangos'),//i18n.t('solicitudes.Eventoeliminadocorrectamente'),
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500
          })         
          
          this.$nextTick(() => {
            this.parametro.rangos[index] = {};

            this.$forceUpdate();

            setTimeout(() => {
              this.parametro.rangos[index] = rangos;
  
              this.parametro.rangos[index].nu_rango_a = null;
              this.parametro.rangos[index].nu_potencia_a = null;
  
              this.$forceUpdate();

            }, 1);
          });
        } else {
          this.rangos_temporales.push(item);
          this.$forceUpdate(); // test
          console.log('rangos temporales', this.rangos_temporales);
        }
      }
      this.$forceUpdate(); // test
    },
    agregarRango() {
      this.parametro.rangos.push({
        id: null,
        nu_rango_a: null,
        nu_potencia_a: null,
        nu_rango_b: null,
        nu_potencia_b: null,
        sn_grupo_rangos: null,
        borrado: null
      });

      this.$forceUpdate();
    },
    removerRango(item, index){
      // valida si el item viene con un id lo cual indica que esta en la pantalla de edicion
      if(item.id) {
        item.borrado = 1;
      } else {
        this.parametro.rangos.splice(index, 1);
      }

      this.$forceUpdate();

    },
    eventoAlerta() {
      alert('escuchando evento desde el modal');
    }
  }
}

</script>
